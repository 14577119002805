<template>
  <v-row align="center">
    <v-col>
      <v-card class="pa-md-4 mx-lg-auto">
        <v-img
          class="white--text align-top"
          height="200px"
          src="https://e-d-e-n.site/%E3%82%A8%E3%83%BC%E3%83%86%E3%83%A9%E3%82%A4%E3%83%88.jpg"
        >
          <v-card-title
            class="text-h3"
          >
            {{ $t("message.aetheryte") }}
          </v-card-title>
        </v-img>
        <div class="text-h5  mx-1 my-2">
          {{ $t("message.aetheryte_4") }}
        </div>
        <v-textarea
          class="pa-md-2 mx-lg-auto mx-2"
          outlined
          clearable
          clear-icon="mdi-close-circle"
          :label="$t('message.aetheryte_1')"
          @change="Search"
        />
        <v-text-field
          v-model="outputJp"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_2-1')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputJp)"
        />
        <v-text-field
          v-model="outputEn"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_2-2')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputEn)"
        />
        <v-text-field
          v-model="outputKo"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_2-3')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputKo)"
        />
        <v-text-field
          v-model="outputCHS"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_2-4')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputCHS)"
        />
        <v-text-field
          v-model="outputCHT"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_2-5')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputCHT)"
        />
        <v-text-field
          v-model="size"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_3')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(size)"
        />
        <v-textarea
          v-model="outputAll"
          class="pa-md-2 mx-lg-auto mx-2"
          clearable
          readonly
          outlined
          :label="$t('message.aetheryte_5')"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copy(outputAll)"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'AetheryteView',
    metaInfo: {
      title: 'Aetheryte',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。 ' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
        json_items: [],
        AreaNameEn: '',
        outputJp: '',
        outputEn: '',
        outputKo: '',
        outputCHS: '',
        outputCHT: '',
        outputAll: '',
        size: '',
      }
    },
    created: async function () {
      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return (y + '-' + m + '-' + d)
      }
      this.targetDate = new Date()
      this.targetDate = formatDate(new Date(this.targetDate.getFullYear(), this.targetDate.getMonth(), this.targetDate.getDate() - 2))
      // console.log(this.targetDate)
      if (!this.$store.artworkItems) {
        await axios.get(`../../../aetheryte.json?timestamp=${new Date().getTime()}`).then(res => {
          res.data.forEach(data => {
            this.json_items.push(data)
          })
        })
      }
      // console.log(this.json_items)
    },
    methods: {
      copy: function (event) {
        this.$copyText(event).then(function (e) {
          alert('Copied')
          console.log(e)
        }, function (e) {
          alert('Can not copy')
          console.log(e)
        })
      },
      Search: function (event) {
        // エリア判定
        var text = event
        text = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) { // 半角化
          return String.fromCharCode(s.charCodeAt(0) - 65248)
        })
        // console.log('半角化:' + text)
        text = text.toLowerCase()// 小文字化
        // console.log('小文字化:' + text)
        text = text.replace(/\r?\n/g, '')// 改行排除
        // console.log('改行排除:' + text)
        text = text.replace('goblin', '')
        // console.log('ゴブリン削除:' + text)

        var result = ''
        // output初期化
        this.outputJp = ''
        this.outputEn = ''
        this.outputKo = ''
        this.outputCHS = ''
        this.outputCHT = ''
        this.AreaNameEn = ''
        this.size = ''
        this.outputAll = ''
        // console.log(text)

        if (text.match('ゴブ.*|ｺﾞﾌﾞ.*|ごぶ.*|gob.*|하늘잔.*|高腳孤丘.*|高脚孤丘.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*sultana.*|.*나나모 대풍차.*|.*나나모대풍차.*|.*娜娜莫大風車.*|.*娜娜莫大风车.*') != null) { // ゴブ
          result = text.match('ゴブ.*|ｺﾞﾌﾞ.*|ごぶ.*|gob.*|하늘잔.*|高腳孤丘.*|高脚孤丘.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*sultana.*|.*나나모 대풍차.*|.*나나모대풍차.*|.*娜娜莫大風車.*|.*娜娜莫大风车.*')
          // console.log(result[0])
          this.AreaNameEn = 'The Goblet'
        } else if (text.match('ミスト.*|ﾐｽﾄ.*|みすと.*|mist.*|안갯빛.*|海霧村.*|海雾村.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*topmast.*|.*중층 돛대.*|.*중층돛대.*|.*中桅塔.*|.*中桅塔.*') != null) { // ミスト
          result = text.match('ミスト.*|ﾐｽﾄ.*|みすと.*|mist.*|안갯빛.*|海霧村.*|海雾村.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*topmast.*|.*중층 돛대.*|.*중층돛대.*|.*中桅塔.*|.*中桅塔.*')
          // console.log(result[0])
          this.AreaNameEn = 'Mist'
        } else if (text.match('ラベ.*|ﾗﾍﾞ.*|らべ.*|lav.*|lb.*|라벤더.*|薰衣草苗圃.*|薰衣草苗圃.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*lily.*|.*백합 언덕.*|.*백합언덕.*|.*百合嶺.*|.*百合岭.*') != null) { // ラベ
          result = text.match('ラベ.*|ﾗﾍﾞ.*|らべ.*|lav.*|lb.*|라벤더.*|薰衣草苗圃.*|薰衣草苗圃.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*lily.*|.*백합 언덕.*|.*백합언덕.*|.*百合嶺.*|.*百合岭.*')
          // console.log(result[0])
          this.AreaNameEn = 'The Lavender Beds'
        } else if (text.match('シロガネ.*|ｼﾛｶﾞﾈ.*|しろがね.*|白金.*|白銀.*|shiro.*|시로가네.*|白銀鄉.*|白银乡.*|.*紅梅.*|.*kobai.*|.*홍매전.*|.*紅梅禦殿.*|.*红梅御殿.*') != null) { // シロガネ
          result = text.match('シロガネ.*|ｼﾛｶﾞﾈ.*|しろがね.*|白金.*|白銀.*|shiro.*|시로가네.*|白銀鄉.*|白银乡.*|.*紅梅.*|.*kobai.*|.*홍매전.*|.*紅梅禦殿.*|.*红梅御殿.*')
          // console.log(result[0])
          this.AreaNameEn = 'Shirogane'
        }
        // console.log(this.AreaNameEn)

        // 番地取得※全角対応
        var prots = result[0].match(/[0-9]+\.?[0-9]*/g)
        // console.log(prots)

        // console.log(this.json_items)

        // アパルトメント判定
        if (text.match('.*アパル.*|.*ｱﾊﾟﾙ.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*紅梅.*|.*apart.*|.*lily.*|.*topmast.*|.*sultana.*|.*kobai.*|.*아파트.*|.*백합 언덕.*|.*백합언덕.*|.*중층 돛대.*|.*중층돛대.*|.*나나모 대풍차.*|.*나나모대풍차.*|.*홍매전.*|.*娜娜莫大風車.*|.*娜娜莫大风车.*|.*中桅塔.*|.*中桅塔.*|.*百合嶺.*|.*百合岭.*|.*紅梅禦殿.*|.*红梅御殿.*') != null) {
          this.size = 'apartment'
          switch (this.AreaNameEn) {
            case 'The Goblet':
              if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*|.*扩建.*|.*擴建.*') != null) {
                this.outputJp = '［拡張街］ナナモ大風車'
                this.outputEn = 'The Sultana\'s Breath Subdivision.'
                this.outputKo = '[확장 구역] 나나모 대풍차'
                this.outputCHS = '［扩建区］娜娜莫大风车'
                this.outputCHT = '［擴建區］娜娜莫大風車'
              } else {
                this.outputJp = 'ナナモ大風車'
                this.outputEn = 'The Sultana\'s Breath'
                this.outputKo = '나나모 대풍차'
                this.outputCHS = '娜娜莫大风车'
                this.outputCHT = '娜娜莫大風車'
              }
              break
            case 'Mist':
              if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*|.*扩建.*|.*擴建.*') != null) {
                this.outputJp = '［拡張街］トップマスト'
                this.outputEn = 'The Topmast Subdivision.'
                this.outputKo = '[확장 구역] 중층 돛대'
                this.outputCHS = '［扩建区］中桅塔'
                this.outputCHT = '［擴建區］中桅塔'
              } else {
                this.outputJp = 'トップマスト'
                this.outputEn = 'The Topmast.'
                this.outputKo = '중층 돛대'
                this.outputCHS = '中桅塔'
                this.outputCHT = '中桅塔'
              }
              break
            case 'The Lavender Beds':
              if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*|.*扩建.*|.*擴建.*') != null) {
                this.outputJp = '［拡張街］リリーヒルズ'
                this.outputEn = 'Lily Hills Subdivision.'
                this.outputKo = '[확장 구역] 백합 언덕'
                this.outputCHS = '［扩建区］百合岭'
                this.outputCHT = '［擴建區］百合嶺'
              } else {
                this.outputJp = 'リリーヒルズ'
                this.outputEn = 'Lily Hills.'
                this.outputKo = '백합 언덕'
                this.outputCHS = '百合岭'
                this.outputCHT = '百合嶺'
              }
              break
            case 'Shirogane':
              if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*|.*扩建.*|.*擴建.*') != null) {
                this.outputJp = '［拡張街］紅梅御殿'
                this.outputEn = 'Kobai Goten Subdivision.'
                this.outputKo = '[확장 구역] 홍매전'
                this.outputCHS = '［扩建区］红梅御殿'
                this.outputCHT = '［擴建區］紅梅禦殿'
              } else {
                this.outputJp = '紅梅御殿'
                this.outputEn = 'Kobai Goten.'
                this.outputKo = '홍매전'
                this.outputCHS = '红梅御殿'
                this.outputCHT = '紅梅禦殿'
              }
              break
          }
        } else {
          for (var key of Object.keys(this.json_items)) {
            // 連想配列のキーと配列の値が一致するか検索
            // console.log(this.json_items[key].AreaNameEn)
            if (this.json_items[key].AreaNameEn === this.AreaNameEn) {
              if (this.json_items[key].Prot === prots[1]) {
                // console.log(this.json_items[key].AetheryteJp)
                // console.log(this.json_items[key].AetheryteEn)
                // console.log(this.json_items[key].AetheryteKo)
                this.outputJp = this.json_items[key].AetheryteJp
                this.outputEn = this.json_items[key].AetheryteEn
                this.outputKo = this.json_items[key].AetheryteKo
                this.outputCHS = this.json_items[key].AetheryteCHS
                this.outputCHT = this.json_items[key].AetheryteCHT
                this.size = this.json_items[key].Size
                if (text.match('.*個室.*|.*号室.*|.*private room.*|.*room\\d.*|.*room \\d.*|.*개인실.*|.*號房.*|.*号房.*') != null) {
                  this.size = 'FC private room'
                }
              }
            }
          }
        }
        if (this.outputJp !== '') {
          switch (this.$i18n.locale) {
            case 'ja':
              this.outputAll = text + ' [' + this.size + ']' + ' 最寄りエーテライト:' + this.outputJp
              break
            case 'en':
              this.outputAll = text + '[' + this.size + ']' + ' | nearest aetheryte:' + this.outputEn
              break
            case 'ko':
              this.outputAll = text + '[' + this.size + ']' + ' | 가장 가까운 간이 에테라이트:' + this.outputKo
              break
            case 'zh-CN':
              this.outputAll = text + '[' + this.size + ']' + ' | 最近的以太之光:' + this.outputCHS
              break
            case 'zh-TW':
              this.outputAll = text + '[' + this.size + ']' + ' | 最近的以太之光:' + this.outputCHT
              break
          }
        }
      },
    },
  }
</script>
